import { useState } from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import arrayMutators from 'final-form-arrays'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { Field, Form, FormSpy } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { default as MuiTextField } from '@material-ui/core/TextField'
import FormRow from '../../pages/RecordPage/FormRow'
import DialogTitle from '@material-ui/core/DialogTitle'
import { client } from '../../client'
import composeHooks from 'react-hooks-compose'
import validate from './validate'
import { DatePicker } from '../../react-final-form-wrappers'
import useEffectAsync from '../../hooks/useEffectAsync'
import { Autocomplete, TextField } from 'mui-rff'
import { validateEmail } from '../../utils/validate'
import setFieldData from 'final-form-set-field-data'
import { WarningTextField } from './WarningTextField'
import { matchSorter } from 'match-sorter'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { getYearFromDate } from '../../utils/getYearFromDate'
import { assert } from '../../utils/assert'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    // margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed'
  }
}))

const handleSubmit = async ({ values, setOpen }) => {
  const {
    firstName,
    infix,
    lastName,
    email,
    phoneNumber,
    birthDate,
    status,
    practice,
    insurer,
    remarks,
    registrationDate
  } = values
  const response = await client.addFunnel({
    patient: { firstName, infix, lastName, email, phoneNumber, birthDate },
    status,
    practice,
    insurer,
    registrationDate,
    remarks
  })
  const href = `/wachtlijst/${response.data.id}`
  setOpen(false)
  window.location.href = href
}

/**
 * @returns {Promise<string | undefined>}
 * @param {number | undefined} patientId
 */
const getPatientLink = async (patientId) => {
  if (typeof patientId !== 'number') return undefined
  const treatmentPrograms = await client.getTreatmentProgramsByPatientId(
    patientId
  )
  const treatmentId = Array.isArray(treatmentPrograms) && treatmentPrograms[0]
  if (treatmentId) {
    return `/clienten/${patientId}/trajecten/${treatmentId}`
  }
  const funnels = await client.getFunnelsByPatientId(patientId)
  assert(Array.isArray(funnels), 'getFunnelsByPatientId should return array')
  const funnel = funnels.shift()
  assert(!!funnel, 'patient should have at least one funnel')
  const funnelId = funnel.id
  assert(typeof funnelId === 'number', 'funnelId should be a number')
  return `/wachtlijst/${funnelId}`
}

const WarningEngine = ({ mutators: { setFieldData } }) => {
  const { t } = useTranslation('addPatientComponent')

  return (
    <FormSpy
      subscription={{ values: true }}
      onChange={async ({ values }) => {
        if (values.email && validateEmail(values.email)) {
          const data = await client.findPatientByEmailOrPhoneNumber({
            email: values.email
          })
          const link = await getPatientLink(data?.data?.patient?.id)
          setFieldData('email', {
            warning:
              typeof link === 'string' ? (
                <>
                  {t('emailTaken')}&nbsp;
                  <Link href={link}>{t('clickHere')}</Link>
                </>
              ) : undefined
          })
        }
        if (values.phoneNumber) {
          const data = await client.findPatientByEmailOrPhoneNumber({
            phoneNumber: values.phoneNumber
          })
          const link = await getPatientLink(data?.data?.patient?.id)
          setFieldData('phoneNumber', {
            warning:
              typeof link === 'string' ? (
                <>
                  {t('phoneNumberTaken')}&nbsp;
                  <Link href={link}>{t('clickHere')}</Link>
                </>
              ) : undefined
          })
        }
      }}
    />
  )
}

const AddPatientFab = ({
  openState: [open, setOpen],
  classes,
  onSubmit = handleSubmit
}) => {
  const { t } = useTranslation('addPatientComponent')

  const [state, setState] = useState({
    statusOptions: [],
    insurerOptions: [],
    practiceOptions: []
  })
  const [loading, setLoading] = useState(true)

  useEffectAsync(async () => {
    const statusOptions = await client.getFunnelStatusOptions({
      stage: 'INITIAL'
    })
    const insurerOptions = await client.getInsurerOptions()
    const filteredInsurerOptions = insurerOptions.filter(
      (option) => !option.disabled
    )
    const practiceOptions = await client.getPracticeOptions(true)
    setState({
      statusOptions,
      insurerOptions: filteredInsurerOptions,
      practiceOptions
    })
    setLoading(false)
  }, [])

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, { keys: ['name'] })

  if (loading) {
    return null
  }

  return (
    <>
      <Fab
        onClick={() => setOpen(true)}
        color="primary"
        aria-label="Add"
        className={classes.root}
      >
        <AddIcon />
      </Fab>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Form
          onSubmit={(values) => onSubmit({ values, setOpen })}
          subscription={{ submitting: true, pristine: true, invalid: true }}
          validate={validate}
          mutators={{
            setFieldData,
            ...arrayMutators
          }}
          initialValues={{
            patient: {},
            registrationDate: format(new Date(), 'yyyy-MM-dd')
          }}
          render={({ form, handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>{t('addClient')}</DialogTitle>
              <DialogContent>
                <FormRow xs={[4, 3, 5]}>
                  <TextField
                    // required
                    fullWidth
                    name="firstName"
                    label={t('firstName')}
                  />
                  <TextField fullWidth name="infix" label={t('infix')} />
                  <TextField
                    // required
                    fullWidth
                    name="lastName"
                    label={t('lastName')}
                  />
                </FormRow>
                <FormRow xs={[6, 6]}>
                  <WarningTextField fullWidth name="email" label={t('email')} />
                  <WarningTextField
                    fullWidth
                    name="phoneNumber"
                    label={t('phoneNumber')}
                  />
                </FormRow>
                <FormRow xs={[6, 6]}>
                  <Autocomplete
                    fullWidth
                    name="status"
                    options={state.statusOptions}
                    getOptionLabel={(option) => option && option.name}
                    getOptionSelected={(option, value) =>
                      option ? option.id === value.id : false
                    }
                    renderInput={(params) => (
                      <MuiTextField {...params} label="Status" />
                    )}
                    filterOptions={filterOptions}
                  />
                  <Autocomplete
                    fullWidth
                    name="practice"
                    options={state.practiceOptions}
                    getOptionLabel={(option) => option && option.name}
                    getOptionSelected={(option, value) =>
                      option ? option.id === value.id : false
                    }
                    renderInput={(params) => (
                      <MuiTextField {...params} label={t('practice')} />
                    )}
                    filterOptions={filterOptions}
                  />
                </FormRow>
                <FormRow xs={[6, 6]}>
                  {/* <Autocomplete */}
                  {/*   fullWidth */}
                  {/*   name="insurer" */}
                  {/*   options={state.insurerOptions} */}
                  {/*   getOptionLabel={(option) => */}
                  {/*     option && */}
                  {/*     `${option.name}${ */}
                  {/*       option.note ? ' ' + option.note + ' ' : ' ' */}
                  {/*     }(${option.uzovi})` */}
                  {/*   } */}
                  {/*   getOptionSelected={(option, value) => */}
                  {/*     option ? option.id === value.id : false */}
                  {/*   } */}
                  {/*   renderInput={(params) => ( */}
                  {/*     <MuiTextField {...params} label={t('insurer')} /> */}
                  {/*   )} */}
                  {/*   filterOptions={filterOptions} */}
                  {/* /> */}
                  <Field
                    // style={{ width: 300 }}
                    name="birthDate"
                    label={t('birthDate')}
                    component={DatePicker}
                  />
                  <Field
                    name="registrationDate"
                    label={t('registrationDate')}
                    component={DatePicker}
                  />
                </FormRow>
                <FormRow>
                  <TextField
                    multiline
                    fullWidth
                    name="remarks"
                    label={t('remarks')}
                  />
                </FormRow>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  {t('cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={pristine || invalid}
                >
                  {t('save')}
                </Button>
              </DialogActions>
              <WarningEngine mutators={form.mutators} />
            </form>
          )}
        />
      </Dialog>
    </>
  )
}

export default composeHooks({
  openState: () => useState(false),
  classes: () => ({ classes: useStyles() })
})(AddPatientFab)

import { useRef, useCallback, FormEvent, useEffect } from 'react'
import { assert } from '../../../utils/assert'

type FormOnChange = (e: FormEvent) => void
type SaveFn<T> = (changes: Partial<T>) => void

export function useAutosave<T>(save: SaveFn<T>): FormOnChange {
  const changes = useRef<Partial<T>>({})
  const timeoutRef = useRef(0)
  const onTimeout = useCallback(() => {
    save(changes.current)
    changes.current = {}
  }, [save])
  const onChange = useCallback(
    (e: FormEvent) => {
      console.log('called on change')
      const elem = e.target as HTMLInputElement
      assignByPath(changes.current, elem.name, getInputValue(elem))
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(onTimeout, 500)
    },
    [onTimeout]
  )
  useEffect(() => onTimeout, [onTimeout])
  return onChange
}

const isObject = (obj: unknown): obj is Record<string, unknown> => {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null
}

const assignByPath = (obj: unknown, path: string, value: unknown): void => {
  const parts = path.split('.')
  const last = parts.slice().pop()
  assert(typeof last === 'string', 'path must have at least one element')
  parts.slice(0, -1).forEach((part) => {
    assert(isObject(obj), '[sanity check] obj must be an object')
    if (!isObject(path[part])) {
      obj[part] = {}
    }
    obj = obj[part]
  })
  assert(isObject(obj), '[sanity check] obj must be an object')
  obj[last] = value
}

const getInputValue = (elem: HTMLInputElement): boolean | number | string => {
  if (elem.tagName === 'select') {
    return parseInt(elem.value)
  }
  if (elem.type === 'checkbox') {
    return elem.checked
  }
  return elem.value
}

import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
import '@fontsource/work-sans'
import App from './App'
import { createStore, StoreProvider } from 'easy-peasy'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  createGenerateClassName,
  createTheme,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core/styles'
import storeModel from './model'
import './i18n'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://9685fc5499e98d3b69b55fd7ecd0ba6d@o4506716255223808.ingest.sentry.io/4506716257320960',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        'api.slaapmakend.site',
        'api.slaapmakend.site.nl'
      ]
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'api.slaapmakend.site',
    'api.slaapmakend.site.nl',
    'staging.slaapmakend.site'
  ],
  // Session Replay - decreased 0, as our quota is 50/month. We can still enable it manually in the code, if we need to debug sth
  replaysSessionSampleRate: 1.0, // temporarily changed to 100%, as unlimited replay trial was activated
  replaysOnErrorSampleRate: 1.0, // temporarily changed to 100%, as unlimited replay trial was activated
})

const store = createStore(storeModel)

const theme = createTheme({
  typography: {
    fontFamily: '"Work Sans"'
  }
})

/**
 * IMPORTANT: dependencies might use their own classname generator. In order to
 * avoid conflicts with the default 'j' prefix, we use 'c' instead.
 */
const generateClassName = createGenerateClassName({
  productionPrefix: 'c'
})

ReactDOM.render(
  <StylesProvider generateClassName={generateClassName}>
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  </StylesProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

import axios from 'axios'
import {
  FunnelStatus,
  Practice,
  Location,
  Psychologist,
  User,
  Insurer
} from '../types'
import { sentryDebugLog } from "../utils/sentryDebugLog";

const debug = (message: string) => sentryDebugLog(message, 'Client')


class Client {
  url = process.env.REACT_APP_API || 'http://localhost:3030'
  token = null

  constructor(url?: string) {
    if (url) {
      this.url = url
    }
  }

  getToken() {
    return sessionStorage.getItem('jwt')
  }

  getHeaders() {
    return { Authorization: `Bearer ${this.getToken()}` }
  }

  // NOTE: add more configuration items here
  getConfig() {
    return { headers: this.getHeaders() }
  }

  isLoggedIn() {
    return this.getToken()
  }

  async login(email, password, pin) {
    const response = await axios.post(`${this.url}/login`, {
      email: email,
      password: password,
      pin: pin
    })
    return response.data
  }

  async sendPin(email, password) {
    const response = await axios.put(`${this.url}/send_pin`, {
      email: email,
      password: password
    })
    return response.status === 200
  }

  async isAccessible() {
    try {
      const response = await axios.get(`${this.url}`)
      return response.status === 200
    } catch (e) {
      return false
    }
  }

  async saveTreatmentProgram(id, program) {
    const response = await axios.put(
      `${this.url}/treatment_programs/${id}`,
      program,
      this.getConfig()
    )
    return response.data
  }

  async updatePatient(id, patient) {
    const response = await axios.put(
      `${this.url}/patients/${id}`,
      patient,
      this.getConfig()
    )
    return response.data
  }

  async updateInvoice(id, invoice) {
    const response = await axios.patch(
      `${this.url}/invoices/${id}`,
      invoice,
      this.getConfig()
    )
    return response.data
  }

  async searchPatients(name) {
    const url = `${this.url}/patients`
    const response = await axios.get(url, {
      params: {
        search: name
      },
      ...this.getConfig()
    })
    return response.data || [] // return empty array if no data
  }

  async findPatientByEmailOrPhoneNumber({ email, phoneNumber }) {
    const url = `${this.url}/patients`
    const response = await axios.get(url, {
      ...this.getConfig(),
      params: { email, phoneNumber }
    })
    return response.data
  }

  async getPatients(pageSize, page) {
    const url =
      pageSize !== undefined && page !== undefined
        ? `${this.url}/patients?pageSize=${pageSize}&page=${page}`
        : `${this.url}/patients`
    const response = await axios.get(url, this.getConfig())
    return response.data
  }

  async getFunnels(status = null, page = 1, perPage = null) {
    let query = `?page=${page}`
    if (status) query += `&status=${status}`
    if (perPage) query += `&perPage=${perPage}`

    const url = `${this.url}/funnels${query}`
    const response = await axios.get(url, this.getConfig())
    console.log('Fetched funnels', response)
    return response.data
  }

  async getFunnelById(funnelId) {
    const url = `${this.url}/funnels/${funnelId}`
    const response = await axios.get(url, this.getConfig())
    return response.data
  }

  async removeLastFunnelStatusLogItem(funnelId) {
    const url = `${this.url}/funnels/${funnelId}/statusLog/?last`
    const response = await axios.delete(url, this.getConfig())
    return response.data
  }

  async getFunnelsByPatientId(patientId) {
    const url = `${this.url}/patients/${patientId}/funnels`
    const response = await axios.get(url, this.getConfig())
    return response.data
  }

  async getPatientCount() {
    const url = `${this.url}/patients?count=true`
    const response = await axios.get(url, this.getConfig())
    return response.data
  }

  async getTreatmentProgramById(programId) {
    const response = await axios.get(
      `${this.url}/treatment_programs/${programId}`,
      this.getConfig()
    )

    return response.data
  }

  async getTreatmentProgramsByPatientId(patientId) {
    const response = await axios.get(
      `${this.url}/patients/${patientId}/treatment_programs`,
      this.getConfig()
    )

    return response.data
  }

  async getTreatmentProgramsIdsByPatientId(patientId) {
    const treatmentPrograms = await this.getTreatmentProgramsByPatientId(
      patientId
    )
    return treatmentPrograms.map((el) => el.id)
  }

  getOptions(key) {
    const cached = sessionStorage.getItem(key)
    return cached ? JSON.parse(cached) : null
  }

  setOptions(key, object) {
    sessionStorage.setItem(key, JSON.stringify(object))
  }

  clearCacheItem(key) {
    sessionStorage.removeItem(key)
  }

  async getCachedOptions(fn, key) {
    let options = this.getOptions(key)
    if (options) {
      return options
    }
    options = await fn()
    this.setOptions(key, options)
    return options
  }

  async getFunnelStatusOptions(params?: unknown): Promise<Array<FunnelStatus>> {
    const response = await axios.get(`${this.url}/funnel_statuses`, {
      ...this.getConfig(),
      params
    })
    return response.data
  }

  async updateFunnel(funnelId, funnel) {
    const response = await axios.patch(
      `${this.url}/funnels/${funnelId}`,
      funnel,
      this.getConfig()
    )
    return response.data
  }

  async updateFunnelStatus(funnelId, statusId) {
    const response = await axios.post(
      `${this.url}/funnels/${funnelId}/statusLog`,
      { id: statusId },
      this.getConfig()
    )
    return response.data
  }

  async updateFunnelStatusByTreatmentProgram(programId, statusId) {
    const response = await axios.post(
      `${this.url}/treatment_programs/${programId}/funnel/statusLog`,
      { id: statusId },
      this.getConfig()
    )
    return response.data
  }

  async setFunnelTreatmentProgram(funnelId, treatmentProgramId, statusId) {
    const response = await axios.patch(
      `${this.url}/funnels/${funnelId}`,
      {
        treatmentProgram: { id: treatmentProgramId },
        status: { id: statusId }
      },
      this.getConfig()
    )
    return response.data
  }

  async changeOrder(funnelId, operation: 'up' | 'down' | 'top' | 'bottom') {
    const response = await axios.patch(
      `${this.url}/funnels/${funnelId}/order`,
      {
        funnelId,
        operation
      },
      this.getConfig()
    )
    return response.data
  }

  async addFunnel(funnel) {
    const response = await axios.post(
      `${this.url}/funnels`,
      funnel,
      this.getConfig()
    )
    return response.data
  }

  async getPracticeOptions(primary = false): Promise<Practice[]> {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/practices`,
        this.getConfig()
      )

      if (primary) {
        return response.data.filter((el) => el.primary === true)
      } else {
        return response.data
      }
    }, `practiceOptions${primary ? 'Primary' : ''}`)
  }

  async updatePractice(
    id: number | string,
    data: Partial<Practice>
  ): Promise<void> {
    await axios.patch(`${this.url}/practices/${id}`, data, this.getConfig())
  }

  async addPracticeLocation(
    practiceId: number,
    locationId: number
  ): Promise<void> {
    await axios.post(
      `${this.url}/practices/${practiceId}/locations`,
      { locationId },
      this.getConfig()
    )
  }

  async removePracticeLocation(
    practiceId: number,
    locationId: number
  ): Promise<void> {
    await axios.delete(
      `${this.url}/practices/${practiceId}/locations/${locationId}`,
      this.getConfig()
    )
  }

  async getLocationOptions(): Promise<Location[]> {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/locations`,
        this.getConfig()
      )

      return response.data
    }, 'locationOptions')
  }

  async updateLocation(
    id: number | string,
    data: Partial<Location>
  ): Promise<Location> {
    const res = await axios.patch(
      `${this.url}/locations/${id}`,
      data,
      this.getConfig()
    )
    return res.data
  }

  async createLocation(data: Omit<Location, 'id'>): Promise<Location> {
    const res = await axios.post(
      `${this.url}/locations`,
      data,
      this.getConfig()
    )
    return res.data
  }

  async getSalutationOptions() {
    // TODO: 'Anders' should not be hardcoded
    const getName = ({ salutationShort }) =>
      salutationShort === '' ? 'Anders' : salutationShort
    return this.getCachedOptions(async () => {
      const response = await axios.get(`${this.url}/genders`, this.getConfig())
      return response.data.map((el) => {
        return { id: el.id, name: getName(el) }
      })
    }, 'salutationOptions')
  }

  async getCountryOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/countries`,
        this.getConfig()
      )

      return response.data
    }, 'countryOptions')
  }

  async getInsurerOptions(): Promise<Insurer[]> {
    return this.getCachedOptions(async () => {
      const response = await axios.get(`${this.url}/insurers`, this.getConfig())

      return response.data
    }, 'insurerOptions')
  }

  async getPsychologistOptions(isActive: boolean): Promise<Psychologist[]> {
    return await this.getCachedOptions(async () => {
      const response = await axios.get(`${this.url}/psychologists`, {
        ...this.getConfig(),
        //@ts-ignore //todo
        isActive
      })
      const psychologistOptions = response.data
      const onlyWithName = psychologistOptions.filter(
        (option) => option.user?.firstName
      )
      return onlyWithName
    }, 'psychologistOptions')
  }

  async getReferrerTypeOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/referrer_types`,
        this.getConfig()
      )

      return response.data
    }, 'referrerTypeOptions')
  }

  async getReferrerOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/referrers`,
        this.getConfig()
      )

      return response.data
    }, 'referrerOptions')
  }

  async searchReferrers(name) {
    const response = await axios.get(
      `${this.url}/referrers?search=${name}`,
      this.getConfig()
    )

    return response.data
  }

  async getProductOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(`${this.url}/products`, this.getConfig())

      return response.data
    }, 'productOptions')
  }

  async getHoNOSTypes() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/ho_nos_type`,
        this.getConfig()
      )

      return response.data
    }, 'hoNOSTypes')
  }

  async getCareDemandTypes() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/care_demand_types`,
        this.getConfig()
      )

      return response.data
    }, 'careDemandTypes')
  }

  async getDisorderOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/disorders`,
        this.getConfig()
      )

      return response.data
    }, 'disorderOptions')
  }

  async getSortedTreatmentPrograms(patientId) {
    const response = await axios.get(
      `${this.url}/patients/${patientId}/treatment_programs`,
      this.getConfig()
    )

    return response.data
  }

  async getActivityOptions(legacy: boolean) {
    return this.getCachedOptions(async () => {
      const response = await axios.get(`${this.url}/activities`, {
        ...this.getConfig(),
        //@ts-ignore //todo
        legacy
      })
      return response.data
    }, 'activityOptions')
  }

  async getActivity(id) {
    const response = await axios.get(
      `${this.url}/activities/${id}`,
      this.getConfig()
    )

    return response.data
  }

  async getTreatmentTypeOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/treatment_types`,
        this.getConfig()
      )

      return response.data
    }, 'treatmentTypeOptions')
  }

  async getRoleOptions() {
    const response = await axios.get(`${this.url}/roles`, this.getConfig())

    return response.data
  }

  async getClosingReasonOptions(isLegacy: boolean) {
    return this.getCachedOptions(async () => {
      const response = await axios.get(`${this.url}/closing_reasons`, {
        ...this.getConfig(),
        // @ts-ignore //todo
        isLegacy
      })

      return response.data
    }, 'closingReasonOptions')
  }

  async getConsultationTypeOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/consultation_types`,
        this.getConfig()
      )

      return response.data
    }, 'consultationTypeOptions')
  }

  async getOccupationTypeOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/occupation_types`,
        this.getConfig()
      )

      return response.data
    }, 'occupationTypeOptions')
  }

  async getSettingTypeOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/setting_types`,
        this.getConfig()
      )

      return response.data
    }, 'settingTypeOptions')
  }

  async getDurationOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/durations`,
        this.getConfig()
      )

      return response.data
    }, 'durationOptions')
  }

  async getConsultationPrices() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/consultation_prices`,
        this.getConfig()
      )

      return response.data
    }, 'consultationPrices')
  }

  async getTemplateTypeOptions() {
    return this.getCachedOptions(async () => {
      const response = await axios.get(
        `${this.url}/template_types`,
        this.getConfig()
      )

      return response.data
    }, 'templateTypeOptions')
  }

  async uploadFile(file, programId, type, onUploadProgress) {
    try {
      debug(`uploadFile args: ${JSON.stringify({ file, programId, type, onUploadProgress })}`)
    }
    catch (e) {
      debug(' failed to log args for uploadFile: ' + e)
    }

    const url = `${this.url}/treatment_programs/${programId}/upload?type=${type}`
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${this.getToken()}`
      },
      onUploadProgress: onUploadProgress
    }
    const res = await axios.post(url, formData, config)
    try {
      debug('uploadFile res ' + JSON.stringify(res))
    }
    catch (e) {
      debug('failed to log res for uploadFile: ' + e)
    }
    return res
  }

  async uploadFileToFunnel(file, funnelId, type, onUploadProgress) {
    const url = `${this.url}/funnels/${funnelId}/upload?type=${type}`
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${this.getToken()}`
      },
      onUploadProgress: onUploadProgress
    }
    return await axios.post(url, formData, config)
  }

  /**
   * Using hack described here: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
   */
  async fetchFile(fileId, filename) {
    const config = this.getConfig()
    // @ts-ignore //todo
    config.responseType = 'blob' // this is apparently required
    const response = await axios.get(`${this.url}/files/${fileId}`, config)

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove() // added this additional to the original Gist
  }

  async addActivity(programId, primaryPsychologistId) {
    const data = primaryPsychologistId
      ? { psychologist: { id: primaryPsychologistId } }
      : {}

    const response = await axios.post(
      `${this.url}/treatment_programs/${programId}/activities`,
      data,
      this.getConfig()
    )

    return response.data
  }

  async addActivity2022(programId, primaryPsychologistId) {
    const data = { settingType: { id: 1 } }

    if (primaryPsychologistId) {
      // @ts-ignore //todo
      data.psychologist = { id: primaryPsychologistId }
    }

    const response = await axios.post(
      `${this.url}/treatment_programs/${programId}/activities2022`,
      data,
      this.getConfig()
    )

    return response.data
  }

  async addDisorder(disorderId, programId, isPrimary, remark) {
    const response = await axios.post(
      `${this.url}/treatment_programs/${programId}/disorders`,
      { id: disorderId, isPrimary, remark },
      this.getConfig()
    )

    return response.data
  }

  async addUser(data) {
    const response = await axios.post(
      `${this.url}/users`,
      data,
      this.getConfig()
    )
    return response.data
  }

  async changePassword(id, oldPassword, newPassword) {
    const response = await axios.put(
      `${this.url}/users/${id}?oldPassword=${oldPassword}`,
      { password: newPassword },
      { ...this.getConfig() }
    )
    return response.data.id
  }

  async getAllUsers(): Promise<
    {
      email: string
      firstName: string
      lastName: string
      isActive: boolean
      title: string
      roles: { id: number; name: string; description: string }[]
    }[]
  > {
    const { data } = await axios.get(`${this.url}/users`, this.getConfig())
    return data
  }

  async addPatient(values) {
    /**
     * TODO: we probably check for some minimal fields, like firstname and lastname
     * and shouldn't accept otherwise
     */
    const data = values || {}

    const response = await axios.post(
      `${this.url}/patients`,
      data,
      this.getConfig()
    )

    return response.data.identifiers[0].id
  }

  async removePatient({ id, reason }) {
    const response = await axios.delete(`${this.url}/patients/${id}`, {
      ...this.getConfig(),
      params: { reason }
    })

    return response.data
  }

  async removeTreatmentProgram(programId) {
    const response = await axios.delete(
      `${this.url}/treatment_programs/${programId}`,
      this.getConfig()
    )

    return response.data
  }

  async getLastAddedPatientId() {
    // @ts-ignore //todo
    const patients = await this.getPatients()

    return patients[patients.length - 1].id
  }

  async addTreatmentProgram(patientId, fields) {
    const response = await axios.post(
      `${this.url}/treatment_programs`,
      { patient: { id: patientId }, ...fields },
      this.getConfig()
    )

    return response.data
  }

  async removeActivity(activityId) {
    const response = await axios.delete(
      `${this.url}/registered_activities/${activityId}`,
      this.getConfig()
    )

    return response.data
  }

  async removeActivity2022(activityId) {
    const response = await axios.delete(
      `${this.url}/registered_activities2022/${activityId}`,
      this.getConfig()
    )

    return response.data
  }

  async removeDisorder(disorderId) {
    const response = await axios.delete(
      `${this.url}/registered_disorders/${disorderId}`,
      this.getConfig()
    )

    return response.data
  }

  async removeFile(fileId) {
    const response = await axios.delete(
      `${this.url}/files/${fileId}`,
      this.getConfig()
    )

    return response.data
  }

  async removeFunnel(funnelId) {
    const response = await axios.delete(
      `${this.url}/funnels/${funnelId}`,
      this.getConfig()
    )

    return response.data
  }

  async addNote(programId) {
    const response = await axios.post(
      `${this.url}/treatment_programs/${programId}/session_notes`,
      {},
      this.getConfig()
    )

    return response.data
  }

  async removeNote(noteId) {
    const response = await axios.delete(
      `${this.url}/session_notes/${noteId}`,
      this.getConfig()
    )

    return response.data
  }

  async updateNote(noteId, note) {
    const response = await axios.patch(
      `${this.url}/session_notes/${noteId}`,
      note,
      this.getConfig()
    )

    return response.data
  }

  async findFunnelByTreatmentProgramId(treatmentProgramId) {
    const response = await axios.get(
      `${this.url}/treatment_programs/${treatmentProgramId}/funnel`,
      this.getConfig()
    )

    return response.data
  }

  async findNotesByTreatmentProgramId(treatmentProgramId) {
    const response = await axios.get(
      `${this.url}/treatment_programs/${treatmentProgramId}/session_notes`,
      this.getConfig()
    )

    return response.data
  }

  async addressLookup(postalCode) {
    const response = await axios.get(
      `${this.url}/addressLookup?postalCode=${postalCode}`,
      this.getConfig()
    )
    return response.data
  }

  async findPractitionerByAgb(agb) {
    const response = await axios.get(`${this.url}/agb/${agb}`, this.getConfig())
    return response.status === 200 ? response.data : {}
  }

  async checkPasswordStrength(password) {
    const response = await axios.post(
      `${this.url}/actions/checkPasswordStrength?password=${password}`,
      undefined,
      this.getConfig()
    )
    return response.data.data.errors
  }

  async addIpAddress(address) {
    const response = await axios.post(
      `${this.url}/whitelist`,
      { address },
      this.getConfig()
    )
    return response.data
  }

  async createInvoice(invoice) {
    const response = await axios.post(
      `${this.url}/invoices`,
      invoice,
      this.getConfig()
    )
    return response.data
  }

  async deleteInvoice(id) {
    const response = await axios.delete(
      `${this.url}/invoices/${id}`,
      this.getConfig()
    )
    return response.data
  }

  async getInvoicesByTreatmentProgram(programId) {
    const response = await axios.get(
      `${this.url}/treatment_programs/${programId}/invoices`,
      this.getConfig()
    )
    return response.data
  }

  async getActivities2022ByTreatmentProgram(programId) {
    const response = await axios.get(
      `${this.url}/treatment_programs/${programId}/activities2022`,
      this.getConfig()
    )
    return response.data
  }

  async getFilesByTreatmentProgram(programId) {
    const response = await axios.get(
      `${this.url}/treatment_programs/${programId}/files`,
      this.getConfig()
    )
    return response.data
  }

  async getWhitelistedAddresses() {
    const response = await axios.get(`${this.url}/whitelist`, this.getConfig())
    return response.data
  }

  async getTemplate(userId, practiceId, fieldName) {
    const response = await axios.get(
      `${this.url}/users/${userId}/practices/${practiceId}/templates/${fieldName}`,
      this.getConfig()
    )
    return response.data
  }

  async createTemplate(text, userId, practiceId, fieldName) {
    const url = `${this.url}/users/${userId}/practices/${practiceId}/templates/${fieldName}`
    const response = await axios.put(url, { text }, this.getConfig())
    return response.data
  }

  // ***** statistics service **********
  async getCaseloadByPsychologist() {
    const response = await axios.get(
      `${this.url}/statistics/caseload`,
      this.getConfig()
    )
    return response.data
  }

  async getTreatmentsPerMonth() {
    const response = await axios.get(
      `${this.url}/statistics/treatments_per_month`,
      this.getConfig()
    )
    return response.data
  }

  async getTreatmentsPerMonthSplitByTherapist() {
    const response = await axios.get(
      `${this.url}/statistics/treatments_per_month_split_by_therapist`,
      this.getConfig()
    )
    return response.data
  }

  async getSessionsPerTherapist() {
    const response = await axios.get(
      `${this.url}/statistics/sessions_per_therapist`,
      this.getConfig()
    )
    return response.data
  }

  async completedTreatmentPrograms() {
    const response = await axios.get(
      `${this.url}/statistics/completed_treatment_programs`,
      this.getConfig()
    )
    return response.data
  }

  async caseload2() {
    const response = await axios.get(
      `${this.url}/statistics/caseload2`,
      this.getConfig()
    )
    return response.data
  }

  async caseload() {
    const response = await axios.get(
      `${this.url}/statistics/caseload`,
      this.getConfig()
    )
    return response.data
  }

  async clientRegistrations() {
    const response = await axios.get(
      `${this.url}/statistics/client_registrations`,
      this.getConfig()
    )
    return response.data
  }

  async diagnoses(year) {
    const response = await axios.get(`${this.url}/statistics/diagnoses`, {
      ...this.getConfig(),
      params: { year }
    })
    return response.data
  }

  async genders(year) {
    const response = await axios.get(`${this.url}/statistics/genders`, {
      ...this.getConfig(),
      params: { year }
    })
    return response.data
  }

  async age() {
    const response = await axios.get(
      `${this.url}/statistics/age`,
      this.getConfig()
    )
    return response.data
  }

  async postcodes() {
    const response = await axios.get(
      `${this.url}/statistics/postcodes`,
      this.getConfig()
    )
    return response.data
  }

  async sessionsPerPractice() {
    const response = await axios.get(
      `${this.url}/statistics/sessions_per_practice`,
      this.getConfig()
    )
    return response.data
  }

  async sessionsPerWeek() {
    const response = await axios.get(
      `${this.url}/statistics/sessions_per_week`,
      this.getConfig()
    )
    return response.data
  }

  async consultationPriceUsage(
    psychologistId?: string | number,
    year?: string | number
  ): Promise<
    Array<{
      price_code: string
      activity_count: number
    }>
  > {
    const response = await axios.get(
      `${this.url}/statistics/consultation_price_usage`,
      { ...this.getConfig(), params: { psychologistId, year } }
    )
    return response.data
  }

  async resetCredentials(email: string): Promise<void> {
    const url = `${this.url}/users/reset_credentials`
    await axios.post(url, { email }, this.getConfig())
  }

  async updateUser(
    userId: number | string,
    data: Partial<User>
  ): Promise<User> {
    const response = await axios.patch(
      `${this.url}/users/${userId}`,
      data,
      this.getConfig()
    )
    return response.data
  }

  async updatePsychologist(
    psychologistId: number | string,
    data: Partial<Psychologist>
  ): Promise<Psychologist> {
    const response = await axios.patch(
      `${this.url}/psychologists/${psychologistId}`,
      data,
      this.getConfig()
    )
    return response.data
  }

  async updateInsurer(
    insurerId: number | string,
    data: Partial<Insurer>
  ): Promise<Insurer> {
    const response = await axios.patch(
      `${this.url}/insurers/${insurerId}`,
      data,
      this.getConfig()
    )
    return response.data
  }

  async createInsurer(data: Omit<Insurer, 'id'>): Promise<Insurer> {
    const response = await axios.post(
      `${this.url}/insurers`,
      data,
      this.getConfig()
    )
    return response.data
  }
}

/**
 * NOTE: we know how to handle UnauthorizedExceptions (redirect to login page)
 * so we want to centralize its handling and rethrow all other exceptions.
 *
 * A class decorator could easily wrap each method with this functionality, but
 * it requires eject the create-react-app. Therefore we implement a poor man's
 * alternative to decorators.
 *
 * An instance of class is instantiated. We then iterate of its methods and wrap
 * it with an exception check, redirecting or rethrowing.
 */

const _client = new Client()

const clientWrapper: Partial<Client> = {}

// eslint-disable-next-line no-unused-vars
for (const key of Object.getOwnPropertyNames(Client.prototype)) {
  const login = () => {
    window.location.href = '/login'
  }

  clientWrapper[key] = async (...args) => {
    try {
      // All methods except login() and isAccessible() require authentication
      if (
        !_client.isLoggedIn() &&
        key !== 'login' &&
        key !== 'isAccessible' &&
        key !== 'sendPin'
      ) {
        login()
      }
      return await _client[key](...args) // call the wrapped method
    } catch (e) {
      //@ts-ignore //todo
      if (e?.response?.status === 401) {
        login()
      } else {
        throw e
      }
    }
  }
}

const inferedClientWrapper = clientWrapper as unknown as Client
export { inferedClientWrapper as client }

import * as Sentry from '@sentry/react'
import authenticationService from "../services/AuthenticationService";

export function sentryDebugLog(message: string, location?: string) {
  const date = (new Date()).toDateString()
  const time = (new Date()).toTimeString()
  const currentUserEmail = authenticationService.getEmail()
  const sentryMessage = `DEBUG: ${message} | ${time} ${date} | user: ${currentUserEmail} | location: ${location}@${window.location.href}`
  console.log(sentryMessage)
  Sentry.captureMessage(sentryMessage, {
    tags: {
      userEmail: currentUserEmail,
      location
    }
  })
}
import { useEffect, useState, useCallback, useRef } from 'react'
import { client } from '../../../client'
import Page from '../../../layout/Page'
import { Location, Practice } from '../../../types'
import LocationsField from './LocationsField'
import './styles.css'
import { useAutosave } from './useAutosave'
import { useInitialData } from './useInitialData'
import { AdminPage } from '../../../layout/AdminPage'

type PracticeRowProps = {
  practice: Practice
  locationOptions: Location[]
}
const PracticeRow = ({
  practice,
  locationOptions
}: PracticeRowProps): JSX.Element => {
  const {
    id,
    name,
    agbCode,
    bankAccountNumber,
    bankAccountName,
    chamberOfCommerceNumber,
    vatNumber
  } = practice
  return (
    <tr>
      <td>
        <input type="text" name={`${id}.name`} defaultValue={name} />
      </td>
      <td>
        <input type="text" name={`${id}.agbCode`} defaultValue={agbCode} />
      </td>
      <td>
        <input
          type="text"
          name={`${id}.bankAccountNumber`}
          defaultValue={bankAccountNumber || ''}
        />
      </td>
      <td>
        <input
          type="text"
          name={`${id}.bankAccountName`}
          defaultValue={bankAccountName || ''}
        />
      </td>
      <td>
        <input
          type="text"
          name={`${id}.chamberOfCommerceNumber`}
          defaultValue={chamberOfCommerceNumber || ''}
        />
      </td>
      <td>
        <input
          type="text"
          name={`${id}.vatNumber`}
          defaultValue={vatNumber || ''}
        />
      </td>
      <td>
        <LocationsField practice={practice} locationOptions={locationOptions} />
      </td>
    </tr>
  )
}

const TableHeader = (): JSX.Element => (
  <thead>
    <tr>
      <th>Practice Name</th>
      <th>Practice Agb Code</th>
      <th>Bank Account Number</th>
      <th>Bank Account Name</th>
      <th>KvK Number</th>
      <th>Vat Number</th>
      <th>Locations</th>
    </tr>
  </thead>
)

const PracticeTable = ({
  practices,
  locationOptions
}: {
  practices: Practice[]
  locationOptions: Location[]
}): JSX.Element => {
  return (
    <table className="simple-table">
      <TableHeader />
      <tbody>
        {practices.map((p) => (
          <PracticeRow
            key={p.id}
            practice={p}
            locationOptions={locationOptions}
          />
        ))}
      </tbody>
    </table>
  )
}

const PracticeListContent = (): JSX.Element => {
  useEffect(() => {
    client.clearCacheItem(`practiceOptions`)
  }, [])
  const [formRef, onChange] = useAutosave()
  const [practices, locationOptions] = useInitialData()

  if (practices.length === 0 || locationOptions.length === 0) {
    return <div>Loading...</div>
  }

  return (
    <>
      <form onChange={onChange} ref={formRef}>
        <p>
          ⚠️ Note: the <b>AGB Code</b> must be unique (cannot repeat) and cannot
          be empty
        </p>
        <PracticeTable
          practices={practices}
          locationOptions={locationOptions}
        />
      </form>
    </>
  )
}

const PracticeListPage = (): JSX.Element => {
  return (
    <AdminPage>
      <PracticeListContent />
    </AdminPage>
  )
}

export default PracticeListPage

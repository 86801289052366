import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import { PlusCircle } from 'mdi-material-ui'
import { FieldArray } from 'react-final-form-arrays'
import Link from '@material-ui/core/Link'
import { client } from '../../client'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from '../../react-final-form-wrappers'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import * as types from '../../types'

const useStyles = makeStyles(() => ({
  selectedOption: {
    marginTop: 10,
    marginLeft: 15,
    marginRight: 10
  }
}))

const FileList = (): JSX.Element => {
  const { t } = useTranslation('filesSection')

  const {
    input: { value: funnelId }
  } = useField('id')

  const handleRemoveFile = async (file: types.File) => {
    await client.removeFile(file.id)
  }

  const handleFetch = async (fileId: string, filename: string) => {
    await client.fetchFile(fileId, filename)
  }

  const handleSubmit = async (
    event: ChangeEvent<unknown>,
    push: (value: types.File) => void
  ) => {
    const target = event.target as HTMLInputElement
    const files = target.files
    if (files === null) {
      return void 0
    }
    const selectedFile = files[0]
    const response = await client.uploadFileToFunnel(
      selectedFile,
      funnelId,
      'other',
      undefined
    )
    push(response.data)
  }

  const classes = useStyles()

  return (
    <FieldArray<types.File>
      name="otherFiles"
      render={({ fields }) => (
        <div style={{ marginTop: 10 }}>
          {fields.map((name, index) => (
            <Field
              name={`${name}`}
              key={name}
              render={({ input: { value } }) =>
                value && (
                  <div style={{ overflow: 'hidden' }}>
                    <Typography
                      className={classes.selectedOption}
                      style={{ float: 'left' }}
                      component="p"
                      variant="body1"
                    >
                      <Link
                        onClick={() => handleFetch(value.id, value.filename)}
                      >
                        {value.filename}
                      </Link>
                    </Typography>
                    <IconButton
                      style={{ float: 'left' }}
                      onClick={() => handleRemoveFile(fields.remove(index))}
                    >
                      <DeleteIcon color="disabled" fontSize="small" />
                    </IconButton>
                  </div>
                )
              }
            />
          ))}
          <Button color="primary" size="small" component="label">
            <PlusCircle />
            {t('otherFile')}
            <Input
              name="otherFile"
              id="otherFile"
              type="file"
              style={{ display: 'none' }}
              onChange={(event) => handleSubmit(event, fields.push)}
            />
          </Button>
        </div>
      )}
    />
  )
}

export default FileList

import { useRef, useCallback, FormEvent } from 'react'
import { client } from '../../../client'
import { Psychologist, User, Practice } from '../../../types'
import { assert } from '../../../utils/assert'

// TODO: make this more universal
export const useAutosave = (): ((e: FormEvent) => void) => {
  const userChanges = useRef<Record<number, Partial<User>>>({})
  const psychologistChanges = useRef<Record<number, Partial<Psychologist>>>({})
  const practiceChanges = useRef<Record<number, Partial<Practice>>>({})

  const timeoutRef = useRef(0)

  const save = useCallback(() => {
    const userEntries = Object.entries(userChanges.current)
    const psychologistEntries = Object.entries(psychologistChanges.current)
    const practiceEntries = Object.entries(practiceChanges.current)
    userChanges.current = {}
    psychologistChanges.current = {}
    practiceChanges.current = {}

    for (const [id, data] of userEntries) {
      client.updateUser(id, data)
    }
    for (const [id, data] of psychologistEntries) {
      client.updatePsychologist(id, data)
    }
    for (const [id, data] of practiceEntries) {
      client.updatePractice(id, data)
    }
  }, [])

  const onChange = useCallback(
    (e: FormEvent) => {
      const elem = e.target as HTMLInputElement
      const name = elem.name
      const isCheckbox = elem.type === 'checkbox'
      const value = isCheckbox ? elem.checked : elem.value
      const [table, id, ...path] = name.split('.')

      const tableRef =
        table === 'user'
          ? userChanges
          : table === 'psychologist'
          ? psychologistChanges
          : table === 'practice'
          ? practiceChanges
          : null

      assert(!!tableRef, 'failed to read data from table')

      if (path.length === 1) {
        const [fieldName] = path
        if (!(id in tableRef.current)) tableRef.current[id] = {}
        tableRef.current[id][fieldName] = value
      } else {
        assert(
          path.length === 2,
          'currently only two levels deep are supported'
        )
        const [relationName, relationField] = path
        assert(relationField === 'id', 'relations can only be changed by id')
        assert(typeof value === 'string', 'relation id cannot be a checkbox')
        const relationId = parseInt(value)
        assert(!isNaN(relationId), 'relation id must be a valid number')
        if (!(id in tableRef.current)) tableRef.current[id] = {}
        tableRef.current[id][relationName] = { id: relationId }
      }
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(save, 1000)
    },
    [save]
  )

  return onChange
}

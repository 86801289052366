import { TextField } from 'mui-rff'
import { client } from '../../client'
import {
  Checkbox,
  DatePicker,
  Select,
  SingleSelect
} from '../../react-final-form-wrappers'
import FormRow from '../RecordPage/FormRow'
import { OnChange } from 'react-final-form-listeners'
import { makeStyles } from '@material-ui/core/styles'
import Field from '../../react-final-form-wrappers/Field'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

const validPostalCode = (value) => {
  if (!value) {
    return false
  }
  const regex = /^[0-9]{4} ?[A-Za-z]{2}$/
  return !!value.match(regex)
}

const validPhoneNumber = (value) => {
  if (!value) {
    return
  }
  const regex = /[0-9]{10}/g
  return value.replace(/[-\s]+/g, '').match(regex) === null
    ? i18n.t('patientDetailsSection.invalidNumber')
    : undefined
}

const useStyles = makeStyles((theme) => ({
  switchLabel: {
    fontSize: 1
  }
}))

const PatientDetailsSection = () => {
  const { t } = useTranslation('patientDetailsSection')
  let changeStreet, changeResidence
  const classes = useStyles()

  return (
    <>
      <Field name="patient.street" subscription={{}}>
        {({ input: { onChange } }) => {
          changeStreet = onChange
          return <></>
        }}
      </Field>
      <Field name="patient.residence" subscription={{}}>
        {({ input: { onChange } }) => {
          changeResidence = onChange
          return <></>
        }}
      </Field>

      <Field
        style={{ width: 300 }}
        name="patient.birthDate"
        component={DatePicker}
        label={t('birthDate')}
      />
      {/* <TextField */}
      {/*   style={{ width: 300, marginLeft: 20 }} */}
      {/*   name="patient.bsn" */}
      {/*   label="BSN" */}
      {/* /> */}
      {/* <Field */}
      {/*   className={classes.switchLabel} */}
      {/*   type="checkbox" */}
      {/*   name="patient.identityVerified" */}
      {/*   label={t('identityVerify')} */}
      {/*   labelPlacement="bottom" */}
      {/*   labelVariant="caption" */}
      {/*   component={Checkbox} */}
      {/* /> */}

      <FormRow xs={[4]}>
        <Select
          style={{ width: 300 }}
          label={t('greeting')}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          getOptions={async () => client.getSalutationOptions()}
          name="patient.gender.id"
        />
      </FormRow>

      <FormRow xs={[4, 3, 5]}>
        <TextField fullWidth name="patient.firstName" label={t('firstName')} />
        <TextField fullWidth name="patient.infix" label={t('infix')} />
        <TextField fullWidth name="patient.lastName" label={t('lastName')} />
      </FormRow>

      <FormRow xs={[4, 3, 5]}>
        <TextField fullWidth name="patient.initials" label={t('initials')} />
        <TextField
          fullWidth
          name="patient.partnerInfix"
          label="Tussenvoegsel partner"
        />
        <TextField
          fullWidth
          name="patient.partnerName"
          label={t('partnerName')}
        />
      </FormRow>

      {/* <FormRow> */}
      {/*   <TextField */}
      {/*     fullWidth */}
      {/*     name="patient.postalCode" */}
      {/*     label="Postcode" */}
      {/*     fieldProps={{ */}
      {/*       format: (value) => */}
      {/*         value && value.replace(/[^0-9a-zA-Z ]/gi, '').toUpperCase(), */}
      {/*       parse: (value) => value && value.toUpperCase() */}
      {/*     }} */}
      {/*   /> */}
      {/*   <TextField */}
      {/*     fullWidth */}
      {/*     name="patient.houseNumber" */}
      {/*     label={t('houseNumber')} */}
      {/*   /> */}
      {/*   <TextField */}
      {/*     fullWidth */}
      {/*     name="patient.houseNumberAddition" */}
      {/*     label={t('addition')} */}
      {/*   /> */}
      {/* </FormRow> */}
      {/**/}
      {/* <FormRow> */}
      {/*   <TextField fullWidth name={'patient.street'} label={t('street')} /> */}
      {/* </FormRow> */}
      {/**/}
      {/* <FormRow> */}
      {/*   <TextField */}
      {/*     fullWidth */}
      {/*     name={'patient.residence'} */}
      {/*     label={t('residence')} */}
      {/*   /> */}
      {/* </FormRow> */}
      {/**/}
      {/* <FormRow> */}
      {/*   <Field */}
      {/*     style={{ width: 500 }} */}
      {/*     label={t('country')} */}
      {/*     getOptionLabel={(i) => i.name} */}
      {/*     getOptionValue={(i) => i.id} */}
      {/*     getOptions={async () => client.getCountryOptions()} */}
      {/*     name="patient.country.id" */}
      {/*     component={SingleSelect} */}
      {/*     defaultLabel="Nederland" */}
      {/*   /> */}
      {/* </FormRow> */}

      <FormRow>
        <TextField
          fullWidth
          name={'patient.phoneNumber'}
          label={t('phoneNumber')}
          fieldProps={{ validation: validPhoneNumber }}
        />
      </FormRow>

      <FormRow>
        <TextField fullWidth name="patient.email" label="E-mail" />
      </FormRow>

      <OnChange name="patient.postalCode">
        {async (value) => {
          if (validPostalCode(value)) {
            try {
              const { street, residence } = await client.addressLookup(value)
              changeStreet(street)
              changeResidence(residence)
            } catch (e) {
              console.error('failed to lookup address by postal code')
              console.error(e)
            }
          }
        }}
      </OnChange>
    </>
  )
}

export default PatientDetailsSection

import { useState } from 'react'
import { client } from '../../../client'
import useEffectAsync from '../../../hooks/useEffectAsync'
import Page from '../../../layout/Page'
import { Insurer } from '../../../types'
import { assert } from '../../../utils/assert'
import { useAutosave } from '../utils/useAutosave'
import styles from './styles.module.css'
import { AdminPage } from '../../../layout/AdminPage'

const useInsurers = (): Insurer[] => {
  const [insurers, setInsurers] = useState<Insurer[]>([])
  useEffectAsync(async () => {
    const data = await client.getInsurerOptions()
    setInsurers(data.sort((a, b) => a.name.localeCompare(b.name)))
  }, [])
  return insurers
}

const InsurerTable = ({ data }: { data: Insurer[] }): JSX.Element => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Uzovi</th>
          <th>Note</th>
          <th>Disabled</th>
          <th>Abbreviation</th>
          <th>Year</th>
        </tr>
      </thead>
      {data.map((insurer) => (
        <tr key={insurer.id}>
          <td>
            <code>{insurer.id}</code>
          </td>
          <td>
            <input
              type="text"
              name={`${insurer.id}.name`}
              defaultValue={insurer.name}
            />
          </td>
          <td>
            <input
              type="text"
              name={`${insurer.id}.uzovi`}
              defaultValue={insurer.uzovi}
            />
          </td>
          <td>
            <input
              type="text"
              name={`${insurer.id}.note`}
              defaultValue={insurer.note || ''}
            />
          </td>
          <td>
            <input
              type="checkbox"
              name={`${insurer.id}.disabled`}
              defaultChecked={insurer.disabled}
            />
          </td>
          <td>
            <input
              type="text"
              name={`${insurer.id}.abbreviation`}
              defaultValue={insurer.abbreviation}
            />
          </td>
          <td>
            <input
              type="number"
              name={`${insurer.id}.year`}
              defaultValue={insurer.year}
            />
          </td>
        </tr>
      ))}
    </table>
  )
}

type Changes = Record<string, Partial<Insurer>>

const save = (changes: Partial<Changes>): void => {
  for (const [id, data] of Object.entries(changes)) {
    assert(!!data, '[sanity check] data should not be undefined')
    client.updateInsurer(id, data)
  }
}

const InsurerListContent = (): JSX.Element => {
  client.clearCacheItem('insurerOptions')
  const insurers = useInsurers()
  const formOnChange = useAutosave<Changes>(save)
  return (
    <form onChange={formOnChange}>
      <InsurerTable data={insurers} />
    </form>
  )
}

const InsurerListPage = (): JSX.Element => {
  return (
    <AdminPage>
      <InsurerListContent />
    </AdminPage>
  )
}

export default InsurerListPage

import { createContext, PropsWithChildren, useContext } from 'react'
import useNotificationBar from './useNotificationBar'

type NotificationBarContextType = {
  showMessage: (message: string, type: 'success' | 'error' | 'info') => void
}

const defaultContext: NotificationBarContextType = {
  showMessage: () => {
    throw new Error('showMessage function should be overriden')
  }
}

const NotificationBarContext = createContext(defaultContext)

export const NotificationBarProvider = ({
  children
}: PropsWithChildren<unknown>): JSX.Element => {
  const { showMessage, NotificationBar } = useNotificationBar({})
  return (
    <NotificationBarContext.Provider value={{ showMessage }}>
      {NotificationBar}
      {children}
    </NotificationBarContext.Provider>
  )
}

export const useNotificationBarContext = (): NotificationBarContextType => {
  const context = useContext(NotificationBarContext)
  return context
}

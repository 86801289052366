import { useState, useCallback } from 'react'
import arrayMutators from 'final-form-arrays'
import { Form, useField } from 'react-final-form'
import { useStoreActions } from 'easy-peasy'
import { useHistory, withRouter } from 'react-router-dom'
import { client } from '../../client'
import useEffectAsync from '../../hooks/useEffectAsync'
import AutoSave from '../../components/AutoSave/AutoSave'
import FormSection from '../RecordPage/FormSection'
import PatientDetailsSection from './PatientDetailsSection'
import { TreatmentStatusSection } from '../RecordPage/sections/TreatmentStatusSection'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { AlertDialog } from '../../components/AlertDialog'
import { PageLoadingIndicator } from '../../components/PageLoadingIndicator'
import { HealthInsuranceSection } from '../RecordPage/sections/HealthInsuranceSection'
import { useOnline } from '../../hooks/useOnline'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { default as MuiTextField } from '@material-ui/core/TextField'
import { TextField } from 'mui-rff'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { useNotificationBar } from '../../components/NotificationBar'
import Box from '@material-ui/core/Box'
import {
  DatePicker,
  Field,
  FileUploadCheckbox,
  Select
} from '../../react-final-form-wrappers'
import FormRow from '../RecordPage/FormRow'
import { useTranslation } from 'react-i18next'
import AuthenticationService from '../../services/AuthenticationService'
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition'
import { DeletePatientDialog } from '../RecordPage/RecordPageContent'
import FilesSection from './FilesSection'
import * as types from '../../types'

const validate = (values) => {
  const errors = {}
  const bsn = values.bsn
  if (bsn && (bsn.length < 8 || bsn.length > 9)) {
    errors['bsn'] = 'Een geldig BSN is 8 of 9 getallen lang.'
  }
  return errors
}

const TherapistSelector = ({ options, onChange }) => {
  const { t } = useTranslation('patientDetailsPage')

  const getFullName = (option) => {
    const { firstName, infix, lastName } = option.user
    return [firstName, infix, lastName].join(' ')
  }

  const sortedOptions = options
    .filter((option) => !!option.user)
    .sort((a, b) => getFullName(a).localeCompare(getFullName(b)))

  return (
    <Autocomplete
      options={sortedOptions}
      getOptionLabel={(option) => getFullName(option)}
      style={{ width: 300 }}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={t('practicioner')}
          variant="outlined"
        />
      )}
    />
  )
}

const StatusSelector = ({ options, onChange }) => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option: any) => option.name}
      style={{ width: 300 }}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <MuiTextField {...params} label="Status" variant="outlined" />
      )}
    />
  )
}

const AssignTherapistDialog = ({
  funnel,
  setLoadingAssignPsychologist,
  loadingAssignPsychologist
}) => {
  const { t } = useTranslation('patientDetailsPage')

  const [psychologistOptions, setPsychologistOptions] = useState<
    types.Psychologist[]
  >([])
  const [funnelStatusOptions, setFunnelStatusOptions] = useState<
    types.FunnelStatus[]
  >([])
  const [selectedPsychologist, setSelectedPsychologist] = useState<any>()
  const [selectedStatus, setSelectedStatus] = useState<any>()
  const history = useHistory()
  const remarksField = useField('remarks')

  const {
    patient: { id: patientId }
  } = funnel

  const handleCreateTreatmentProgram = async () => {
    setLoadingAssignPsychologist(true)
    const { id: programId } = await client.addTreatmentProgram(patientId, {
      primaryPsychologist: { id: selectedPsychologist.id },
      insuranceNumber: funnel.insuranceNumber,
      referralLetterFile: funnel.referralLetterFile,
      initialRemarks: remarksField.input.value
    })
    await client.setFunnelTreatmentProgram(
      funnel.id,
      programId,
      //selectedStatus.id
      13 // TODO: hardcoded status (Telefonische kennismaking ingepland) (SLAAP-257)
    )
    window.location.href = `/clienten/${patientId}/trajecten/${programId}`
  }

  useEffectAsync(async () => {
    const psychologistOptions = await client.getPsychologistOptions(true)
    const sortedPsychologistOptions = psychologistOptions.sort((a, b) =>
      a.user.firstName.localeCompare(b.user.firstName)
    )
    setPsychologistOptions(sortedPsychologistOptions)
    setFunnelStatusOptions(
      await client.getFunnelStatusOptions({ stage: 'ASSIGNED' })
    )
  }, [])

  if (!psychologistOptions || loadingAssignPsychologist) {
    return <LoadingIndicator />
  }

  return (
    <AlertDialog
      fullWidth
      maxWidth="md"
      text={t('alertText')}
      content={
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <TherapistSelector
              options={psychologistOptions}
              onChange={setSelectedPsychologist}
            />
          </Grid>
          {/* <Grid item sm={12} md={4}> */}
          {/* <StatusSelector */}
          {/* options={funnelStatusOptions} */}
          {/* onChange={setSelectedStatus} */}
          {/* /> */}
          {/* </Grid> */}
        </Grid>
      }
      title={t('alertTitle')}
      cancelText={t('alertCancel')}
      confirmText="OK"
      onConfirm={handleCreateTreatmentProgram}
      render={({ onClick }) =>
        funnel.treatmentProgram ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              history.push(
                `/clienten/${patientId}/trajecten/${funnel.treatmentProgram.id}`
              )
            }
          >
            {t('buttonFile')}
          </Button>
        ) : (
          <Button variant="outlined" color="primary" onClick={onClick}>
            {t('buttonAssign')}
          </Button>
        )
      }
    />
  )
}

const PatientDetailsPageContent = ({ match }) => {
  const { t } = useTranslation('patientDetailsPage')
  const classes = useStyles()

  const { setUpdatedJustNow } = useStoreActions((actions: any) => ({
    setUpdatedJustNow: actions.user.setUpdatedJustNow
  }))
  const { funnelId } = match.params
  const [loading, setLoading] = useState(true)
  const [funnel, setFunnel] = useState<types.Funnel | undefined>()
  const { showMessage, NotificationBar } = useNotificationBar(undefined)
  const isOnline = useOnline()
  const [loadingAssignPsychologist, setLoadingAssignPsychologist] =
    useState(false)

  const patient = funnel?.patient

  const canRemoveRecord =
    AuthenticationService.isBackOffice() ||
    AuthenticationService.isAdministrator() ||
    AuthenticationService.isSuperUser()

  const save = async (values, errors) => {
    console.log('Saving', values)
    console.log('Errors', errors)

    if (!isOnline) {
      showMessage(t('offlineMessage'), 'error')
      return
    }

    try {
      await client.updateFunnel(funnelId, values)
      setUpdatedJustNow(true)
      setTimeout(() => setUpdatedJustNow(false), 5000)
    } catch (e: any) {
      // If there's no response, the request failed
      if (!e.response) {
        showMessage(t('responseFailMessage'), 'error')
        return
      }

      // If there's a response, but it contains an error, something went wrong in the backend
      switch (e.response.status) {
        case 409:
          showMessage(t('response409ErrorMessage'), 'error')
          break
        default:
          showMessage(t('responseErrorMessage'), 'error')
          break
      }
    }
  }

  const handleRemovePatient = async (reason) => {
    await client.removeFunnel(funnel?.id)
    await client.removePatient({ id: funnel?.patient.id, reason })

    window.location.href = '/'
  }

  const handleAddReferralLetter = async (referralLetterFile) => {
    if (typeof funnel === 'undefined') {
      return
    }
    const response = await client.uploadFileToFunnel(
      referralLetterFile,
      funnel.id,
      'referralLetter',
      undefined
    )
    funnel.referralLetterFile = response.data
    return response.data
  }
  const handleDeleteReferralLetter = async (file) => {
    await client.removeFile(file.id)
  }

  useEffectAsync(async () => {
    setLoading(true)
    try {
      const fetchedFunnel = await client.getFunnelById(funnelId)
      setFunnel(fetchedFunnel)
    } catch (e) {}
    setLoading(false)
  }, [match.params.patientId])

  // don't show "invoice only" locations in the location dropdown
  const getValidLocationOptions = useCallback(async () => {
    const allLocationOptions = await client.getLocationOptions()
    return allLocationOptions.filter((l) => !l.invoiceOnly)
  }, [])

  if (loading) return <PageLoadingIndicator />

  if (!funnel)
    return (
      <Typography
        component="h1"
        variant="h6"
        align="left"
        color="textSecondary"
        gutterBottom
        style={{ marginLeft: 15 }}
      >
        {t('funnelmNotFound')}
      </Typography>
    )

  return (
    <Form
      initialValues={funnel}
      onSubmit={save}
      subscription={{ submitting: true, pristine: true, invalid: true }}
      validate={validate}
      validateOnBlur
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        }
      }}
      render={({ handleSubmit, form: { getState, mutators } }) => {
        const patientName = [
          patient?.firstName,
          patient?.infix,
          patient?.lastName
        ]
          .filter((part) => !!part) // ignore falsy parts (empty string, null)
          .map((part: any) => part.trim())
          .join(' ')
        return (
          <div>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography
                  component="h1"
                  variant="h6"
                  align="left"
                  color="textSecondary"
                  gutterBottom
                  style={{ marginLeft: 15 }}
                >
                  {[patient?.firstName, patient?.infix, patient?.lastName].join(
                    ' '
                  )}
                </Typography>
              </Box>
              <Box>
                {canRemoveRecord && (
                  <div className={(classes as any).deleteIcon}>
                    <DeletePatientDialog
                      patientName={patientName}
                      onConfirm={handleRemovePatient}
                    />
                  </div>
                )}
              </Box>
            </Box>
            <form onSubmit={handleSubmit} autoComplete="off">
              <AutoSave
                debounce={1000}
                save={(values) => save(values, getState().errors)}
              />
              <div className="form">
                <FormSection
                  anchor="treatmentStatus"
                  title={t('formTreatmentStatus')}
                  render={() => <TreatmentStatusSection funnel={funnel} />}
                />
                <FormSection
                  anchor="patientDetails"
                  title={t('formTitle')}
                  render={() => (
                    <>
                      <PatientDetailsSection />
                      <FormRow>
                        <Select
                          label={t('practiceLabel')}
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(i) => i.id}
                          getOptions={async () =>
                            client.getPracticeOptions(true)
                          }
                          name="practice.id"
                        />
                        {/* <Select */}
                        {/*   label={t('locationLabel')} */}
                        {/*   getOptionLabel={(i) => i.name} */}
                        {/*   getOptionValue={(i) => i.id} */}
                        {/*   getOptions={getValidLocationOptions} */}
                        {/*   name="location.id" */}
                        {/* /> */}
                      </FormRow>
                      {/* <HealthInsuranceSection /> */}
                      <FormRow>
                        <Field
                          style={{ width: 300 }}
                          name="registrationDate"
                          label={t('registrationDate')}
                          component={DatePicker}
                        />
                      </FormRow>
                      <FormRow>
                        <TextField
                          fullWidth
                          multiline
                          name="remarks"
                          label={t('remarksLabel')}
                        />
                      </FormRow>
                      <FormRow>
                        <Field
                          label={t('referral')}
                          name="referralLetterFile"
                          type="referralLetter"
                          onSubmit={(file) => handleAddReferralLetter(file)}
                          onDelete={handleDeleteReferralLetter}
                          component={FileUploadCheckbox}
                        />
                        <FilesSection />
                      </FormRow>
                    </>
                  )}
                />
                <AssignTherapistDialog
                  setLoadingAssignPsychologist={setLoadingAssignPsychologist}
                  loadingAssignPsychologist={loadingAssignPsychologist}
                  funnel={funnel}
                />
              </div>
            </form>
            {NotificationBar}
          </div>
        )
      }}
    />
  )
}

export default withRouter(PatientDetailsPageContent)

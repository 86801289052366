import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { ReactNode } from 'react'

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  toolbar: theme.mixins.toolbar
}))

export const AdminPage = ({ children }: { children: ReactNode }) => {
  const classes = useStyles()

  return (
    <div style={{ width: '100%' }}>
      <Box display="flex">
        <main className={classes.content}>
          <Box m={3} mt={11}>
            {children}
          </Box>
        </main>
      </Box>
    </div>
  )
}

import Page from '../../../layout/Page'
import { client } from '../../../client'
import { useEffect, useState } from 'react'
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Menu
} from '@material-ui/core'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowParams
} from '@material-ui/data-grid'
import { Menu as MenuIcon } from '@material-ui/icons'
import { useNotificationBar } from '../../../components/NotificationBar'
import {
  NotificationBarProvider,
  useNotificationBarContext
} from '../../../components/NotificationBar/notificationBarContext'
import { AdminPage } from '../../../layout/AdminPage'

const MenuButton = (props: GridCellParams) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const { showMessage } = useNotificationBarContext()
  const open = Boolean(anchor)
  const email = props.row.email as string
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }
  const handleClose = () => {
    setAnchor(null)
  }
  const handleReset = () => {
    handleClose()
    showMessage('Resetting credentials...', 'info')
    client
      .resetCredentials(email)
      .then(() => showMessage('Credentials reset', 'success'))
      .catch(() => showMessage('Failed to reset credentials', 'error'))
  }
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu open={open} onClose={handleClose} anchorEl={anchor}>
        <MenuItem>
          <Button color="primary" variant="outlined" onClick={handleReset}>
            Reset Credentials
          </Button>
        </MenuItem>
      </Menu>
    </div>
  )
}

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First Name', flex: 0.6 },
  { field: 'lastName', headerName: 'Last Name', flex: 0.6 },
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'isActive', headerName: 'Active', flex: 0.4 },
  { field: 'roles', headerName: 'Roles', flex: 1 },
  {
    field: 'actions',
    headerName: ' ',
    renderCell: MenuButton
  }
]

type RowData = {
  id: string
  firstName: string
  lastName: string
  email: string
  isActive: string
  roles: string
}

const formatUserData = (
  data: Awaited<ReturnType<typeof client.getAllUsers>>
): RowData[] => {
  return data.map((u) => ({
    ...u,
    id: u.email,
    isActive: u.isActive ? '✅ Active' : '❌ Deactivated',
    roles: u.roles.map((r) => r.name).join(', ')
  }))
}

const UserListContent = () => {
  const [users, setUsers] = useState<RowData[]>([])
  const [search, setSearch] = useState('')
  useEffect(() => {
    client.getAllUsers().then(formatUserData).then(setUsers)
  }, [])
  const filteredData = users.filter((d) =>
    JSON.stringify(d).toLowerCase().includes(search.toLowerCase())
  )
  return (
    <Paper style={{ height: 400, width: '100%' }}>
      <NotificationBarProvider>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            gap: '12px',
            padding: '12px'
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Typography variant="h5">List of registered users</Typography>
        </Box>
        <Box style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
          <DataGrid
            columns={columns}
            rows={filteredData}
            autoPageSize={true}
            disableSelectionOnClick
          />
        </Box>
      </NotificationBarProvider>
    </Paper>
  )
}

const UserListPage = (): JSX.Element => (
  <AdminPage>
    <UserListContent />
  </AdminPage>
)

export default UserListPage

import './styles.css'
import { useAutosave } from './useAutosave'
import { usePracticeLocationOptions } from './initialData'
import LocationsField from './LocationsField'
import { Psychologist } from '../../../types'

export const PsychologistTable = ({
  psychologists
}: {
  psychologists: Psychologist[]
}): JSX.Element => {
  const locations = usePracticeLocationOptions()

  return (
    <table
      className="psychologistlistpage-table"
      style={{ marginTop: '1rem', tableLayout: 'auto' }}
    >
      <thead>
        <tr>
          <th>Id</th>
          <th>First Name</th>
          <th>Infix</th>
          <th>Last Name</th>
          <th>Practice Name</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th>AGB-code Personal</th>
          <th>AGB-code Practice</th>
          <th>Bank Account Number</th>
          <th>Bank Account Name</th>
          <th>KvK Number</th>
          <th>Vat Number</th>
          <th>Location</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        {psychologists.map((psychologist) => (
          <tr key={psychologist.id}>
            <td>
              <code>{psychologist.id}</code>
            </td>
            <td>
              <input
                style={{ width: '6rem' }}
                type="text"
                name={`user.${psychologist.user.id}.firstName`}
                defaultValue={psychologist.user.firstName}
              />
            </td>
            <td>
              <input
                style={{ width: '4rem' }}
                type="text"
                name={`user.${psychologist.user.id}.infix`}
                defaultValue={psychologist.user.infix || ''}
              />
            </td>
            <td>
              <input
                style={{ width: '10rem' }}
                type="text"
                name={`user.${psychologist.user.id}.lastName`}
                defaultValue={psychologist.user.lastName || ''}
              />
            </td>
            <td>
              {psychologist?.practice && (
                <input
                  style={{ width: '18rem' }}
                  type="text"
                  name={`practice.${psychologist?.practice?.id}.name`}
                  defaultValue={psychologist?.practice?.name}
                />
              )}
            </td>
            <td>
              <input
                style={{ width: '6rem' }}
                type="text"
                name={`psychologist.${psychologist.id}.phoneNumber`}
                defaultValue={psychologist.phoneNumber || ''}
              />
            </td>
            <td>
              <input
                style={{ width: '12rem' }}
                type="text"
                name={`user.${psychologist.user.id}.email`}
                defaultValue={psychologist.user.email}
                disabled
              />
            </td>
            <td>
              <input
                style={{ width: '6rem' }}
                type="text"
                name={`psychologist.${psychologist.id}.agbCode`}
                defaultValue={psychologist.agbCode}
              />
            </td>
            <td>
              {psychologist?.practice && (
                <input
                  style={{ width: '6rem' }}
                  type="text"
                  name={`practice.${psychologist?.practice?.id}.agbCode`}
                  defaultValue={psychologist?.practice?.agbCode}
                />
              )}
            </td>
            <td>
              {psychologist?.practice && (
                <input
                  style={{ width: '14rem' }}
                  type="text"
                  name={`practice.${psychologist?.practice?.id}.bankAccountNumber`}
                  defaultValue={psychologist?.practice?.bankAccountNumber || ''}
                />
              )}
            </td>
            <td>
              {psychologist?.practice && (
                <input
                  style={{ width: '18rem' }}
                  type="text"
                  name={`practice.${psychologist?.practice?.id}.bankAccountName`}
                  defaultValue={psychologist?.practice?.bankAccountName || ''}
                />
              )}
            </td>
            <td>
              {psychologist?.practice && (
                <input
                  style={{ width: '6rem' }}
                  type="text"
                  name={`practice.${psychologist?.practice?.id}.chamberOfCommerceNumber`}
                  defaultValue={
                    psychologist?.practice?.chamberOfCommerceNumber || ''
                  }
                />
              )}
            </td>
            <td>
              {psychologist?.practice && (
                <input
                  style={{ width: '8rem' }}
                  type="text"
                  name={`practice.${psychologist?.practice?.id}.vatNumber`}
                  defaultValue={psychologist?.practice?.vatNumber || ''}
                />
              )}
            </td>
            <td>
              {psychologist?.practice && (
                <LocationsField
                  practice={psychologist?.practice}
                  locationOptions={locations}
                />
              )}
            </td>
            <td>
              <input
                style={{ width: '2rem' }}
                type="checkbox"
                name={`user.${psychologist.user.id}.isActive`}
                defaultChecked={psychologist.user.isActive}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

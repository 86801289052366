import jwtDecode from 'jwt-decode'
import { client } from '../client/client'
import NotAuthenticatedException from '../exceptions'

class AuthenticationService {
  async login(email, password, pin) {
    const jwt = await client.login(email, password, pin)
    sessionStorage.setItem('jwt', jwt)
    localStorage.clear()
    window.location.href = '/'
  }

  async sendPin(email, password) {
    return await client.sendPin(email, password)
  }

  getJwtPayload() {
    try {
      const token = sessionStorage.getItem('jwt')
      return jwtDecode(token)
    } catch {
      throw new NotAuthenticatedException()
    }
  }

  getUserId() {
    const payload = this.getJwtPayload()
    return payload?.userId
  }

  hasRole(role) {
    const payload = this.getJwtPayload()
    return payload.roles?.includes(role)
  }

  getEmail() {
    return this.getJwtPayload().email
  }

  isSuperUser() {
    return this.hasRole('SUPER_USER')
  }

  isAdministrator() {
    return this.hasRole('ADMINISTRATOR')
  }

  isPractitioner() {
    return this.hasRole('PRACTITIONER')
  }

  isBackOffice() {
    return this.hasRole('BACKOFFICE')
  }

  logout() {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = '/login'
  }

  isAuthenticated() {
    return sessionStorage.getItem('jwt')
  }
}

export default new AuthenticationService()

import { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { AlertDialog } from '../AlertDialog'
import warning from 'warning'
import { LoadingIndicator } from '../LoadingIndicator'
import { useTranslation } from 'react-i18next'
import { sentryDebugLog } from "../../utils/sentryDebugLog";

const debug = (message) => sentryDebugLog(message, 'FileUploadCheckbox')

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  input: {
    display: 'none'
  },
  checkbox: {
    color: theme.palette.primary.main + '!important'
  }
}))

const renderDeleteButton = ({ onClick }) => (
  <IconButton style={{ marginLeft: 5 }} aria-label="Delete" onClick={onClick}>
    <DeleteIcon fontSize="small" color="disabled" />
  </IconButton>
)

/**
 * Control the type of value stored by passing in onSubmit.
 *
 * Examples:
 *     - if you only want the filename stored, return the filename in onSubmit.
 *     - if you submit the file to an API, you may want to store the returned record
 *
 * The updated `value` is then emitted to `onChange`.
 */
const FileUploadCheckbox = (props) => {
  const { t } = useTranslation('fileUploadButtonComponent')

  const {
    label, // label for the upload button
    value, // File stored, structure: {id, uuid, filename, mimeType, createdAt, lastModifiedAt, treatmentProgram}
    onSubmit, // callback executed when new file is submitted
    onChange, // callback executed when value changes (always after onSubmit)
    onDelete // callback executed when file is deleted (calls onChange afterwards)
  } = props

  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  warning(
    onChange,
    "FileUploadCheckbox is a controlled content, but onChange wasn't set"
  )

  const handleSelectFile = async (event) => {
    debug(`before setLoading(true) in handleSelectFile`)
    setLoading(true)
    debug(`after setLoading(true) in handleSelectFile`)
    const targetFile = event.target.files[0]
    try {
      debug(`targetFile in handleSelectFile ${JSON.stringify(targetFile)}`)
    } catch (error) {
      debug('failed to stringify target file ' + error)
    }

    let newValue = targetFile
    if (onSubmit) {
      debug('onSubmit present')
      newValue = await onSubmit(targetFile)
      try {
        debug(`newValue in handleSelectFile ${JSON.stringify(newValue)}`)
      } catch (error) {
        debug('failed to stringify newValue ' + error)
      }
    } else {
      debug('onSubmit NOT present, leaving default value for targetFile')
    }
    try {
      debug('after onSubmit in handleSelectFile. newValue: ' + JSON.stringify(newValue))
    } catch (e) {
      debug('failed to stringify newValue')
    }
    onChange && onChange(newValue)
    debug(`after onChange in handleSelectFile`)
    setLoading(false)
    debug(`after setLoading(false) in handleSelectFile`)
  }

  const handleRemoveFile = async () => {
    setLoading(true)
    onDelete && (await onDelete(value))
    onChange && onChange(undefined)
    setLoading(false)
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div>
      <Checkbox
        disabled
        checked={!!value}
        color="primary"
        className={classes.checkbox}
      />
      <Button color="primary" component="label" className={classes.button}>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleSelectFile}
        />
        <AttachFileIcon />
        {label}
      </Button>
      {value && (
        <AlertDialog
          text={t('alertDialogText')}
          title={t('alertDialogTitle')}
          cancelText={t('alertDialogCancel')}
          confirmText={t('alertDialogConfirm')}
          onConfirm={handleRemoveFile}
          render={renderDeleteButton}
        />
      )}
    </div>
  )
}

export default FileUploadCheckbox

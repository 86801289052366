import { useState } from 'react'
import { FormSpy } from 'react-final-form'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { client } from '../../../../client'
import { makeStyles } from '@material-ui/core/styles'
import FileList from './FileList'
import { AlertDialog } from '../../../../components/AlertDialog'
import { LoadingIndicator } from '../../../../components/LoadingIndicator'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  selectedOption: {
    marginTop: 10,
    marginLeft: 15,
    marginRight: 10
  }
}))

const renderDeleteButton = ({ onClick }) => (
  <IconButton style={{ marginLeft: 5 }} aria-label="Delete" onClick={onClick}>
    <DeleteIcon fontSize="small" color="disabled" />
  </IconButton>
)

const CurrentFileList = ({ form, values, typesToShow }) => {
  const { t } = useTranslation('filesSection')

  const labelMap = {
    privacyStatementFile: t('privacyStatementFile'),
    treatmentAgreementFile: t('treatmentAgreementFile'),
    hoNOSResultFile: t('hoNOSResultFile'),
    referralLetterFile: t('referralLetterFile'),
    startLetterFile: t('startLetterFile'),
    endLetterFile: t('endLetterFile')
  }

  const files = (({
    privacyStatementFile,
    treatmentAgreementFile,
    hoNOSResultFile,
    referralLetterFile,
    startLetterFile,
    endLetterFile
  }) => ({
    privacyStatementFile,
    treatmentAgreementFile,
    hoNOSResultFile,
    referralLetterFile,
    startLetterFile,
    endLetterFile
  }))(values)

  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  const handleFetch = async (fileId, filename) => {
    await client.fetchFile(fileId, filename)
  }

  /**
   * IMPORTANT: without removing the file from the TreatmentProgram first, the
   * API will remove the entire TreatmentProgram. Therefore, we first delete
   * from the form.
   */
  const handleRemoveFile = async (fileType, fileId) => {
    setLoading(true)
    form.change(fileType, undefined)
    await client.removeFile(fileId)
    setLoading(false)
  }

  if (loading) {
    return <LoadingIndicator />
  }

  const nodes = []
  // eslint-disable-next-line no-unused-vars
  for (const [fileType, file] of Object.entries(files)) {
    if (typesToShow && !typesToShow.includes(fileType)) {
      continue
    }
    file &&
      nodes.push(
        <div style={{ overflow: 'hidden' }} key={file.uuid}>
          <Typography
            className={classes.selectedOption}
            style={{ float: 'left' }}
            component="p"
            variant="body1"
          >
            <Link onClick={() => handleFetch(file.id, file.filename)}>
              {labelMap[fileType]}: {file.filename}
            </Link>
          </Typography>
          <AlertDialog
            text={t('alertText')}
            title={t('alertTitle')}
            cancelText={t('alertCancel')}
            confirmText={t('alertConfirm')}
            onConfirm={() => handleRemoveFile(fileType, file.id)}
            render={renderDeleteButton}
          />
        </div>
      )
  }
  return nodes
}

const FilesSection = ({ hideFileUploadButton, typesToShow }) => {
  // todo - form spy is redundant here, we can just pass the proper props. Remove it after confirming it is not needed as an optimization
  return (
    <div>
      <FormSpy
        subscription={{ values: true }}
        render={({ form, values }) => (
          <CurrentFileList form={form} values={values} typesToShow={typesToShow} />
        )}
      />
      { (!typesToShow || typesToShow.includes('otherFiles')) && (
        <FileList hideFileUploadButton={hideFileUploadButton} />
      )}
    </div>
  )
}

export default FilesSection

import TreatmentStatusSelector from './TreatmentStatusSelector'
import Grid from '@material-ui/core/Grid'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useEffectAsync from '../../../../hooks/useEffectAsync'
import { client } from '../../../../client'
import { LoadingIndicator } from '../../../../components/LoadingIndicator'
import { useNotificationBar } from '../../../../components/NotificationBar'
import { TFunnelStatusOption } from './types'

const TreatmentStatusSection = ({
  funnel
}: {
  funnel: { status: { id: number }; id: number }
} & RouteComponentProps) => {
  const { t } = useTranslation('treatmentStatusSelector')
  const [funnelStatusOptions, setFunnelStatusOptions] = useState<
    TFunnelStatusOption[]
  >([])

  const [statusSelectorValue, setStatusSelectorValue] = useState(
    funnel.status.id
  )
  const { showMessage, NotificationBar } = useNotificationBar(undefined)

  const handleStatusChange = async ({ funnelId, statusId }) => {
    const data = await client.updateFunnelStatus(funnelId, statusId)
    if (data.status !== 'success') {
      showMessage(t('errorMessage'), 'error')
      return
    }
    setStatusSelectorValue(statusId)
  }

  useEffectAsync(async () => {
    setStatusSelectorValue(funnel.status.id)

    // Fetch all status options and then filter. If we only get the 'ASSIGNED'
    // options, this may result in a blank label, because we didn't fetch it.
    const options = (await client.getFunnelStatusOptions()).filter(
      (option) => !option.isLegacy
    )
    setFunnelStatusOptions(options)
  }, [statusSelectorValue])

  if (!statusSelectorValue || !funnelStatusOptions?.length) {
    return <LoadingIndicator />
  }

  return (
    <>
      <Grid container direction="row">
        <Grid item>
          <TreatmentStatusSelector
            options={funnelStatusOptions}
            label={t('treatmentStatus')}
            value={statusSelectorValue}
            onChange={(statusId) =>
              handleStatusChange({
                funnelId: funnel.id,
                statusId
              })
            }
          />
        </Grid>
      </Grid>
      {NotificationBar}
    </>
  )
}

export default withRouter(TreatmentStatusSection)

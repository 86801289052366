import Page from '../../layout/Page'

const AdminToolsContent = (): JSX.Element => {
  return (
    <>
      <h1>Experimental Admin Tools</h1>
      <p>
        ⚠️ Note: to enable rapid development, these pages are not translated to
        Dutch and not styled. It is only recommended for advanced users.
      </p>
      <ul>
        <li>
          <a href="/userlist">User List</a>
        </li>
        <li>
          <a href="/practicelist">Practice List</a>
        </li>
        <li>
          <a href="/locationlist">Location List</a>
        </li>
        <li>
          <a href="/psychologistlist">Psychologist List</a>
        </li>
        <li>
          <a href="/insurerlist">Insurer List</a>
        </li>
      </ul>
    </>
  )
}

const AdminToolsPage = (): JSX.Element => {
  return <Page content={AdminToolsContent} menu={false} hideMenu />
}

export default AdminToolsPage
